import { track } from "@vercel/analytics/react";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from 'next/router'
import React, { useState } from 'react'

export const Header = () => {
  const trackGetCatalog = () => {
    track('get_catalog_from_header');
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  const router = useRouter()
  const isActive = (path: string) => router.pathname === path

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <Head>
        <title>Jooalan</title>
        <meta property="og:title" content="Jooalan" key="title" />
        <meta property="og:description" content="Asisten virtual yang membantu para penjual memulai dan menjalankan usaha toko online atau offline" key="description" />
      </Head>
      <nav className="z-50 bg-white shadow-sm fixed top-0 w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link href="/">
              <div className="flex cursor-pointer">
                <img
                  src="/landing/img/logo/logo-horiz-black.svg"
                  className="hidden md:flex"
                  alt="logo"
                  width={160}
                  height={46.82}
                />
                <img
                  src="/landing/img/logo/logo-horiz-black.svg"
                  className="block md:hidden"
                  alt="logo"
                  width={120}
                  height={48}
                />
              </div>
            </Link>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
            <Link 
              href="/" 
              className={`${isActive('/') ? 'border-red-600 text-red-700' : 'border-transparent text-gray-600'} hover:border-gray-300 hover:text-gray-800 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
            >
              Beranda
            </Link>
            <Link 
              href="/kelas" 
              className={`${isActive('/kelas') ? 'border-red-600 text-red-700' : 'border-transparent text-gray-600'} hover:border-gray-300 hover:text-gray-800 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
            >
              #KelasCUAN
            </Link>
            <Link href="https://app.jooalan.id"
              className="hover:border-gray-300 hover:text-gray-800 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              <button
                type="submit"
                onClick={() => {
                  track('click_register');
                }}
                className="button-style bg-red-600 text-white px-2 py-2 rounded-r-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                COBA SEKARANG
              </button>
            </Link>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <Link href="https://app.jooalan.id"
              className="hover:border-gray-300 hover:text-gray-800 inline-flex items-center px-1 pt-1 text-sm font-medium"
            >
              <button
                type="submit"
                onClick={() => {
                  track('click_register');
                }}
                className="button-style bg-red-600 text-white px-2 py-2 rounded-r-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                COBA SEKARANG
              </button>
            </Link>
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-600"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isMenuOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            <Link 
              href="/beranda" 
              className={`${isActive('/') ? 'bg-red-50 border-red-600 text-red-700' : 'border-transparent text-gray-600'} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
            >
              Beranda
            </Link>
            <Link 
              href="/kelas" 
              className={`${isActive('/kelas') ? 'bg-red-50 border-red-600 text-red-700' : 'border-transparent text-gray-600'} block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
            >
              #KelasCUAN
            </Link>
          </div>
        </div>
      )}
    </nav>
    </>
  );
};
